<template>
    <!-- <div class="col-1-1 editable">                            
        <div class="order-line" :class="{ highlighted: highlighted}">
            <figure class="image">
                 <img :src="img" :alt="title">
            </figure>
            <div class="info">
                <div class="head">
                    <div class="name">{{title}}</div>
                </div>
                <div class="info-line">

                    <div class="key">{{amountLabel}}</div>
                    <div class="val">
                        <Counter 
                            @amountUpdated="updateAmount($event)"
                            :quantity="quantity" 
                            :maxQuantity="maxQuantity"
                        />
                    </div>
                    <div v-if="productHasCoupon" class="coupon-code">
                        <div
                            class="form-row flex flex-column">
                            <label for="voucher">Coupon code *</label>
                            <input 
                                type="text" 
                                id="voucher"
                                v-model.trim="couponCode"
                                @input="$v.couponCode.$touch()"
                                :class="{error: $v.couponCode.$error}" 
                            />
                            <div v-if="$v.couponCode.$dirty || showErrorMessage">
                                <p class="error" v-if="!$v.couponCode.required">Coupon code {{isRequired}}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="isLoading" class="small-loader">
                        <LoadingSpinner />
                    </div>

                    <div v-else class="buttons flex ">
                        <button v-if="quantity" @click="removeFromOrder()" class="btn btn-primary light-grey small">{{remove}}</button>
                        <button @click="orderProduct()" class="btn btn-primary alternative small">{{orderProductButton}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
    <div class="col-1-1 editable">
        <div class="order-line clearfix" :class="{ highlighted: highlighted}">
            <div class="col-1-1">
                <div class="head">
                    <div class="name">{{title}}</div>
                </div>
            </div>

            <div class="info-line col-1-1 flex align-center">
                <div class="col-2-12 md-col-4-12 xs-col-1-1">
                    <figure class="image flex justify-center">
                        <img :src="img" :alt="title">
                    </figure>
                </div>

                <div class="col-1-12 md-col-4-12 sm-col-2-12 xs-col-4-12">
                    <div class="key">{{amountLabel}}</div>
                </div>
                
                <div class="col-2-12 md-col-4-12 sm-col-6-12 xs-col-8-12">
                    <div class="val">
                        <Counter 
                            @amountUpdated="updateAmount($event)"
                            :quantity="quantity" 
                            :maxQuantity="maxQuantity"
                        />
                    </div>
                </div>

                <div class="col-3-12 md-col-6-12 sm-col-1-1">
                    <div v-if="productHasCoupon" class="coupon-code">
                        <div
                            class="form-row flex flex-column">
                            <label for="voucher">Coupon code *</label>
                            <input 
                                type="text" 
                                id="voucher"
                                v-model.trim="couponCode"
                                @input="$v.couponCode.$touch()"
                                :class="{error: $v.couponCode.$error}" 
                            />
                            <div v-if="$v.couponCode.$dirty || showErrorMessage">
                                <p class="error" v-if="!$v.couponCode.required">Coupon code {{isRequired}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-4-12 md-col-6-12 sm-col-1-1">
                    <div v-if="isLoading" class="small-loader"><LoadingSpinner /></div>
                    <div v-else class="buttons flex justify-end xs-justify-space-between">
                        <button v-if="quantity" @click="removeFromOrder()" class="btn btn-primary light-grey small">{{remove}}</button>
                        <button @click="orderProduct()" class="btn btn-primary alternative small">{{orderProductButton}}</button>
                    </div>
                </div>
            </div>
        </div>                            
    </div>
</template>

<script>
import agent from '../api/agent';
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import Counter from '../components/ui/Counter.vue';
import LoadingSpinner from '../components/ui/LoadingSpinner.vue';

export default {
    name: 'OrderLine',  
    components: { Counter, LoadingSpinner },

    props: {
        img: String,
        title: String,
        quantity: Number,
        maxQuantity: Number,
        highlighted: Boolean
    },

    data() {
        return {
            isLoading: false,
            amount: 0,
            couponCode: '',
            showErrorMessage: false
        }
    },

    validations: {couponCode: { required }},

    computed: {
        ...mapGetters([
            // Settings
            'productHasCoupon',
            // Texts
            'isRequired',
            'orderProductButton',
            'remove',
        ]),
        ...mapGetters({
            // Texts
            amountLabel: 'amount'
        })
    },

    methods: {
        updateAmount(data) {
            this.amount = data.amount;
            this.$emit('quantityUpdated', data);
        },

        validateQuantity() {
            if (this.amount === 0) {
                this.$emit('showModal', true);
            } else {
                this.saveOrder();
            }     
        },

        async saveOrder() {
            try {
                this.isLoading = true;
                const product = this.$store.getters.getProductByName(this.title);
                const currentOrderId = this.$store.getters.getOrderId;

                const order = { orderId: currentOrderId, productId: product.id, quantity: this.amount, coupon: this.couponCode };
                
                const orderId = await agent.order.saveProduct(order);
                
                this.$emit('orderUpdated', true);
                this.$emit('addedToOrder', true);

                this.$store.dispatch('fetchOrder', orderId);
                this.isLoading = false;
            } catch (error) {
                console.log(error.response);
                alert(error.response.data.detail);
                this.isLoading = false;
            }
        },

        orderProduct() {
            if (this.productHasCoupon) {
                if (this.$v.$invalid) {
                   this.showErrorMessage = true;
                }
            }
            this.validateQuantity();
        },

        async removeFromOrder() {
            const product = this.$store.getters.getProductByName(this.title);
            const currentOrderId = this.$store.getters.getOrderId;

            try {
                this.isLoading = true;
                const orderId = await agent.order.removeProduct({data: {orderId: currentOrderId, productId: product.id}});
                this.$emit('addedToOrder', false);
                this.$store.dispatch('fetchOrder', orderId);
                this.isLoading = false;
                this.$emit('removedFromOrder', product.id);
            } catch (error) {
                console.log(error.response);
                alert(error.response.data.detail);
                this.isLoading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.order-line {
    padding: 3rem 0;
    border-bottom: 1px solid $grey-light-1;

    &.highlighted {
        border: 2px solid $grey-light-1;
        border-radius: 0.8rem;
        margin-top: 1rem;
    }
    
    .image img {
        width: 100%;
        max-width: 13rem;
        display: block;
    }

        .head {
            align-items: center;
            margin-bottom: .5rem;
            
            .name {
                margin-left: 6rem;
                font-size: 2.4rem;
            }
        }
    .info {
        flex-grow: 1;

        .info-line {
            font-size: 1.6rem;
            line-height: normal;
            padding: .2rem 0;
            align-items: center;
        }
    }

    .small-loader {
        transform: scale(0.5);
        .loading-spinner {
            padding: 0;
        }
    }

    .buttons .btn:first-child {
        margin-right: 1rem;
    }
}

.coupon-code {
        margin-bottom: 3rem;

        label {
            margin-bottom: 1rem;
            font-size: 1.6rem;
            color: $grey;
        }

        input[type="text"] {
            font-family: 'Roboto Condensed', 'Helvetica Neue', Arial, Helvetica, sans-serif;
            background-color: $grey-light-7;
            border: 1px solid $grey-light-6;
            border-radius: 0.8rem;
            width: 100%;
            padding: 15px;
        }

        input[type="text"].error {
            background-color: $grey-light-7;
            border: 1px solid red;
        }

        .error {
            color: red;
            margin: 0 0 1rem 0;
            font-size: 1.2rem;
        }
    }

    @media only screen and (max-width: 1024px) {
        .coupon-code {
            margin-left: 5rem;
            margin-top: 3rem;
        }

        .buttons {
            margin-right: 5rem;
            margin-top: 3rem;
        }
    }

     @media only screen and (max-width: 810px) {
        .order-line .head {          
            .name {
                margin-left: 1rem;
            }
        }
        .coupon-code {
            margin-left: 0rem;
            margin-top: 3rem;
        }

        .buttons {
            margin-right: 0rem;
            margin-top: 3rem;
        }
    }

    @media only screen and (max-width: 500px) {
        .order-line .image {          
            margin-bottom: 3rem;
        }
    }
// .order-line {
//     display: flex;
//     padding: 3rem 3rem 3rem 0;
//     border-bottom: 1px solid $grey-light-1;

//     &.highlighted {
//         border: 2px solid $grey-light-1;
//         border-radius: 0.8rem;
//         margin-top: 1rem;
//     }
    
//     .image img {
//         width: 100%;
//         max-width: 13rem;
//         display: block;
//     }

//     .info {
//         display: flex;
//         flex-direction: column;
//         flex-grow: 1;
        
//         .head {
//             align-items: center;
//             margin-bottom: .5rem;
            
//             .name {
//                 font-size: 2.4rem;
//             }
//         }
        
//         .head,
//         .info-line {
//             display: flex;
//             justify-content: space-between;
//         }

//         .info-line {
//             font-size: 1.6rem;
//             line-height: normal;
//             padding: .2rem 0;
//             align-items: center;
//         }
//     }

//     .small-loader {
//         transform: scale(0.5);
//         .loading-spinner {
//             padding: 0;
//         }
//     }

//     .buttons .btn:first-child {
//         margin-right: 1rem;
//     }
// }

// .coupon-code {
//         margin-bottom: 3rem;

//         label {
//             margin-bottom: 1rem;
//             font-size: 1.6rem;
//             color: $grey;
//         }

//         input[type="text"] {
//             font-family: 'Roboto Condensed', 'Helvetica Neue', Arial, Helvetica, sans-serif;
//             background-color: $grey-light-7;
//             border: 1px solid $grey-light-6;
//             border-radius: 0.8rem;
//             width: 100%;
//             padding: 15px;
//         }

//         input[type="text"].error {
//             background-color: $grey-light-7;
//             border: 1px solid red;
//         }

//         .error {
//             color: red;
//             margin: 0 0 1rem 0;
//             font-size: 1.2rem;
//         }
//     }
</style>