<template>
  <section class="main-content-section">
    <div class="grid grid-pad">
        <div class="col-1-1">
            <div class="orderstatus-summary textpage clear">
                <div class="my-order" id="my-order">
                    <div class="row clearfix">
                        <div class="col-1-1">
                            <h2 class="page-title"><strong>{{myOrder}}:</strong> {{order.number}}</h2>
                        </div>
                        <div class="col-3-4 xs-col-1-1">
                            <strong>{{lastUpdated}}</strong> {{orderDate}}
                        </div>
                        <div class="col-1-4 xs-col-1-1 align-right">
                            <strong>{{total}}</strong>
                        </div>
                    </div>

                    <div class="row clearfix" id="current-order">
                        <OrderLine
                            v-for="line in order.lines"
                            :key="line.id"
                            :img="line.imageUrl" 
                            :title="line[`name${selectedLanguage}`]" 
                            :quantity="line.quantity"
                            :maxQuantity="getMaxQuantity(line[`name${selectedLanguage}`])" 
                            :highlighted="true"
                            @orderUpdated="confirmOrder"
                            @showModal="noItemsSelected = $event" 
                            @quantityUpdated="updateQuantity($event)"
                            @removedFromOrder="moveProductToUnordered($event)"
                        />
                    </div>

                    <div class="row clearfix" id="extra-order">
                        <div class="col-1-1">
                            <h2 class="page-title sub">{{addMoreProducts}}</h2>
                        </div>
                        <div v-for="product in products" :key="product.id">
                            <OrderLine
                                v-if="product.inStock === 1 && !product.isOnOrder"
                                @orderUpdated="confirmOrder"
                                @addedToOrder="product.isOnOrder = $event"
                                @showModal="noItemsSelected = $event"  
                                :img="product.imageUrl" 
                                :title="product[`name${selectedLanguage}`]" 
                                :quantity="0"
                                :maxQuantity="product.maxQuantity"
                            />
                        </div>    
                    </div>

                    <div class="clearfix" id="order-summary">
                        <div class="col-1-1 no-p">
                            <div class="info-line">
                                <div class="key">{{totalAmountOfProducts}}</div>
                                <div class="val" id="sumproductcount">{{ totalAmount }}</div>
                            </div>
                            <!-- <div class="info-line">
                                <div class="key">Total price</div>
                                <div class="val" id="sumprice">€ {{ totalPriceFixed }}</div>
                            </div> -->
                        </div>
                    </div>
                </div>

                <div class="info-wrap-store">
                    <h2 class="title">{{selectedStoreLabel}}</h2>
                    <div class="wrap">
                        <div class="store-info">
                            <div><strong>{{ order.store.name }}</strong></div>
                            <div>{{selectedStoreData.address}}, {{selectedStoreData.zipCode}}, {{selectedStoreData.city}}</div>
                        </div>
                    </div>
                </div>


                <!-- <div class="flex justify-center">
                    <button class="btn btn-primary alternative">{{proceed}}</button>
                </div> -->
            </div>
        </div>
    </div>
    <WarningMessageModal 
        v-show="noItemsSelected" 
        @closeModal="noItemsSelected = $event"
        :title="youHaveNotSelectedAnyProducts"
        :text="addAtLeastOneItemToYourShoppingList" 
    />
    <WarningMessageModal 
        v-show="showConfirmation" 
        @closeModal="showConfirmation = $event"
        :title="yourOrderHasBeenUpdated"
        :text="itemsHaveBeenAdded"  
    />
</section>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderLine from '@/components/OrderLine';
import WarningMessageModal from '@/components/modals/WarningMessageModal';

export default {
    name: 'EditOrder',
    components: { OrderLine, WarningMessageModal },
    
    data() {
        return {
            totalAmount: 0,
            showConfirmation: false,
            noItemsSelected: false,
        }
    },

    created() {
        this.setDocumentTitle();

        if (this.order === null) {
            this.fetchOrder();
        }

        this.initOrderLines();
        this.getTotalQuantity();
    },

    updated() {
        this.setDocumentTitle();
        this.totalAmount = this.getTotalQuantity();
    },

    mounted() {
        this.totalAmount = this.getTotalQuantity();
    },

    computed: {
        ...mapGetters([
            'metaTitle',
            'selectedLanguage',
            'programStep',
            // Texts
            'myOrder',
            'changeMyOrder',
            'lastUpdated',
            'totalAmountOfProducts',
            'total',
            'amountLabel',
            'addMoreProducts',
            'youHaveNotSelectedAnyProducts',
            'addAtLeastOneItemToYourShoppingList',
            'invalidSpecialCharacters',
            'yourOrderHasBeenUpdated',
            'itemsHaveBeenAdded'
        ]),
        ...mapGetters({
            order: 'getOrder',
            products: 'getProducts',
            selectedStoreLabel: 'selectedStore',
        }),
        orderDate() {
            const date = new Date(this.order.date);
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}u`;
        },
        selectedStoreData() {
            return this.$store.getters.storeByName(this.order.store.name);
        },
    },

    methods: {
        setDocumentTitle() {
            document.title = this.changeMyOrder + this.metaTitle;
        },

        getMaxQuantity(name) {
            return this.$store.getters.getProductByName(name).maxQuantity;
        },

        updateQuantity({action}) {
            if (action === 'increase') {
                ++this.totalAmount;
                }
            if (action === 'decrease') {
                --this.totalAmount;
            }
        },

        getTotalQuantity() {
            const quantities = this.order.lines.map(line => line.quantity);
            return quantities.length > 1 ? quantities.reduce((a, b) => a + b) : quantities[0];
        },

        fetchOrder() {
            this.$store.dispatch('fetchOrder', this.$route.params.orderId);
        },

        initOrderLines() {
            this.order.lines.forEach(line => {
                this.products.forEach(p => {
                    if (line.name1 === p.name1) {
                        p.isOnOrder = true;
                    }
                });
            });
        },

        moveProductToUnordered(id) {
            const prod = this.products.find(p => p.id === id);
            prod.isOnOrder = false;
        },

        confirmOrder() {
            this.showConfirmation = true;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.orderstatus-summary {
    margin-top: 3rem;
    margin-bottom: 10rem;
    
    .page-title {
        font-size: 3.2rem;
        font-weight: 300;
        text-align: left;

        &.sub {
            margin-top: 4rem;
        }
    }

    .new-order {
        margin-top: 0;
    }
}

.textpage {
    border-radius: 0.8rem;
    box-shadow: $shadow-2;
    padding: 6rem;
    background: $white;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 300;
}

.align-right {
    text-align: right;
}

#order-summary {
    border-top: 3px solid $primary-color;
    padding: 2rem 3rem 4rem 3rem;
    
    .info-line {
        display: flex;
        justify-content: space-between;
        padding: .2rem 0;
    }
}

.info-wrap-store {
    display: flex;
    flex-direction: column;
    border: 1px solid $grey-light-8;
    background-color: $white;
    border-radius: 12px;
    padding: 3rem;
    margin: 0 0 4rem 0;
    
    .title {
        font-size: 2.4rem;
        font-weight: 300;
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 700px) {
    .textpage {
        padding: 6rem 1rem;
    }
}

@media only screen and (max-width: 400px) {
    .info-wrap-store {
        text-align: center;
    }
} 
</style>